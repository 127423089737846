export const Contact = {
  tel: "+216 36 38 68 25",
  email: "contact@bet216.tn",
  fax: "+216 36 38 68 25",
  addresse:
    "Immeuble le 45,Bloc A 3eme Etage Bureau A 3.3 , Rue du Japon , Montplaisir 1073- Tunis-Tunisie",
};

export const EndPoints = {
  resendActivation: "/user/resendActivation",
  userManager: "/UserManager",
  publication: "/system/publication/list",
  lonagui_register: "/agent/ws_create_customer.jsp",
  pmug_register: "/customer-service/register",
  lonaci_register: "/customer-service/register",
  resendMail: "/user/resendActivation",
  lonagui_login: "/agent/ws_login_customer.jsp",
  lonaci_login: "/user/auth",
  pmug_login: "/user/auth",
  login_with_phonenumber: "/user/phonenumber-login-api",
  logout: "/user/close-session",
  logoutCustomer: "/user/close-session",
  transactions: "/ser_customer_transaction",
  flexbet_transactions: "/agent/getcustomerTransaction.jsp",
  payment_transactions: "/getTransactions",
  flexbet_agent_transactions: "/transactions",
  user_balance: "get_Solde.jsp",
  updateProfile: "/user/updateProfile",
  changePassword: "/user/updatePassword",
  changePin: "/user/updatePin",
  providers: "/provider-service/",
  slider: "/system/sliders",
  promotions: "/site_info/all",
  DizaPAY_WITHDRAWAL_CONFIRMATION: "/ser_validate_visa_transaction",
  DizaPAY_DEPOSIT_CONFIRMATION: "/ser_validate_visa_transaction",
  DizaPAY_WITHDRAWAL: "/ser_web_retrait_momo_box",
  DizaPAY_DEPOSIT: "/ser_web_recharge_momo_box",
  sports: "/v1/sport/",
  events: "/v1/sport/event",
  saveBet: "/ser_hippique_bet",
  cashout: "/v1/cashout/offer",
  completeCashout: "v1/cashout",
  saveCoupon: "/coupon/save",
  loadCoupon: "/coupon/",
  recover1: "/user/recoverAccount",
  verifyEmail: "/user/validate-email",
  verifyEmail_flexbet: "/agent/flex_bet_show_parieur_by_email.jsp",
  verifyUserName: "/user/validate-user",
  verifyUserName_flexbet: "/agent/flex_bet_show_parieur_by_username.jsp",
  securityQuestions: "/user/getQuestions",
  recover2: "/user/recoverAccount/changePassword",
  LIVE_DATA: "/v1/sport/event/liveEvents",
  all_sports: "/v1/sport/list",
  calendar: "/calendar",
  verifyPhone: "/user/validate-phn",
  AIRTEL_DEPOSIT: "/airtel_mobile.jsp",
  AIRTEL_WITHDRAWAL: "/airtel_mobile.jsp",
  PMUC_DEPOSIT: "/coupon_refill_local.jsp",
  PMUC_WITHDRAWAL: "/get_RefCode.jsp",
  BET216WITHDRAWAL: "/v1/ser_generate_withdrawal_code",
  SOBFLOUSVOUCHER: "/ser_refill_local_coupon",
  SOBFLOUS: "/ser_sobflous_recharge",
  BET216: "/ser_refill_local_coupon",
  SPORTCASH_DEPOSIT: "/ser_refill_local_coupon",
  SPORTCASHWITHDRAWAL_WITHDRAWAL: "/v1/ser_generate_withdrawal_code",
  VIAMOBILE: "/ser_refill_local_coupon",
  BANKTRANSFER: "/ser_bank_transfer",
  LATEST_RESULTS: "/v1/sport/results/period/",
  LATETS_WINNERS: "/agent-servicev2/winners/5",
  REQUEST_OTP: "/user/otp/generate",
  savePaymentAccnt: "/provider-service/sendConfirmCode",
  confirmOtpPayment: "/provider-service/confirmAccount",
  sessionverification: "/API/customer/session/validate",
  gratuits: "/gameList/getGratuitPdf",
  contactUs: "/user/contact",
  cagnottes: "/sport/sliders",
  gameList: "/v1/sport/gameList",
  systemConfigs: "/system/configs",
  v1_verify_ticket: "v1/transaction/verify-ticket/",
  resendVerificationCodeBySMS: "/user/resendSMSActivationCode",
  confirmCodeURL: "/user/verifyAccountBySms",
  admin_sliders: "/admin/presentation/sliders",
  LONANI_WITHDRAWAL: "/ser_generate_withdrawal_code",
  LONANI:"/ser_refill_local_coupon",
  refreshToken: "/user/refreshToken",
  confirm_account: "/user/verifyAccount",
  renewSession: "/user/renew-session",
  transactions_ne: "/transactions",
  verifyTicket: "/ser_hippique_customer_bets_history"
};

export const GAME_TYPES = {
  CE: "Long Champ",
  SI: "Unitaire",
  CP: "Champ Reduit",
  CT: "Champ Total",
};

export const TurfEndpoints = {
  getActiveGames: "/soccer/get_active_games.jsp",
  getRaceReport: "/ws_web_mobile_rapport.jsp",
};

export const MISC = {
  expiry: 25,
  channel: 3,
  RECEIVER_PHONENUMBER: "",
};

export const limits = {
  min: "400",
  max_ticket: "100000",
  max_online: "3275000",
  max_gain: "32750000",
  max_odds: "10000",
  max_event: "23",
};
