import { ModalService } from 'src/app/providers/modal-service/ModalService';
import { ConfigService } from './../../../providers/config/config.service';
import { UserManagerService } from '../../../providers/services/userManager/user-manager.service';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MdePopoverTrigger } from '@material-extended/mde';
import { DatePipe } from '@angular/common';
import { locale } from 'moment';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { SkeletonServiceService } from 'src/app/components/skeleton-service.service';
declare var $: any;
@Component({
  selector: 'app-my-transactions',
  templateUrl: './my-transactions.component.html',
  styleUrls: ['./my-transactions.component.scss']
})
export class MyTransactionsComponent implements OnInit {
  activeHeader: any;
  status: any = 'all';
  fromDate: any = new DatePipe(moment.locale('en')).transform(new Date(), 'yyyy-MM-dd');
  toDate: any = new DatePipe(moment.locale('en')).transform(new Date(), 'yyyy-MM-dd');
  transactions: any =[];
  dateChangedEvent = false;
  imagesRepo: any;
  cashoutvalue: any;
  isLoading: boolean;
  isProcessing: boolean;
  activeTransaction = '';
  errorMsg: any;
  params: any;
  constructor(public userManagerService: UserManagerService,
    public modalService: ModalService, public configs: ConfigService,
    public skeleton: SkeletonServiceService,
    private translate: TranslateService) {
    this.activeHeader = this.headers[0];
    this.imagesRepo = this.configs.PROXIES.IMAGES_REPO;
  }
  headers: Array<any> = [{
    name: 'Tous les paris',
    id: 1
  }, {
    name: 'En cours',
    id: 2
  }, {
    name: 'Cash Out effectué',
    id: 3
  }, {
    name: 'Gagné(s)',
    id: 4
  }, {
    name: 'Perdu(s)',
    id: 5
  }, {
    name: ' Annulé(s)',
    id: 6
  }
  ];
  ngOnInit(){

  };
  formatDate(dir){
    // console.log(moment.locale())
    var datePipe = new DatePipe(moment.locale());
    if(dir == 'from'){
      this.fromDate = datePipe.transform(this.fromDate, 'MM/dd/yyyy');
    }else{
      this.fromDate = datePipe.transform(this.fromDate, 'MM/dd/yyyy');
    }
  }
  ngAfterViewInit() {
    // this.fromDate = new Date().setHours(0, 0, 0, 0);
    // this.toDate = new Date().setHours(23, 59, 0, 0)
    const context = this;
    this.getHistory();
    // $('#dateFrom').datepicker({
    //   dateFormat: 'mm/dd/yy',
    //   onSelect: (newDate) => {
    //     this.dateChangedEvent = true;
    //     const dateArr = newDate.split("/");
    //     var fromdt = new Date();
    //     fromdt.setMonth(Number(dateArr[0]) - 1);
    //     fromdt.setFullYear(dateArr[2]);
    //     fromdt.setDate(dateArr[1]);
    //     context.fromDate = fromdt.setHours(0, 0, 0, 0);
    //   }
    // });
    // $('#dateTo').datepicker({
    //   dateFormat: 'mm/dd/yy',
    //   onSelect: (newDate) => {
    //     this.dateChangedEvent = true;
    //     const dateArr = newDate.split("/");
    //     var fromdt = new Date();
    //     fromdt.setMonth(Number(dateArr[0]) - 1);
    //     fromdt.setFullYear(dateArr[2]);
    //     fromdt.setDate(dateArr[1]);
    //     newDate = fromdt.setHours(23, 59, 0, 0);
    //     context.toDate = newDate;
    //   }
    // });
  }

  getHistory() {
    if(!this.configs.useGateway){
      this.params = {
        lg_Customer_Account_ID: this.userManagerService.userData.customer_ID,
        transactiontype: "B",
        day: this.fromDate,
        day2: this.toDate
      }
      this.isLoading = true;

      this.userManagerService.getTransactions(this.params).then(data => {
        if(data.length > 0)
       {
          this.transactions = data;
       }}).finally(()=>{
        this.isLoading = false;
       })
       return
    }
    this.params = {
      uid:  this.userManagerService.userData.customer_ID ,
      // status: this.status,
      begin: new Date(this.fromDate).setHours(0, 0, 0, 0),
      end: new Date(this.toDate).setHours(23, 59, 0, 0),
    };
    // if (this.dateChangedEvent) {
    //   this.params = {
    //     uid:  this.userManagerService.userData.customer_ID,
    //     // uid: this.userManagerService.userData.customer_ID,
    //     begin: new Date(this.fromDate).setHours(0, 0, 0, 0),
    //     end: new Date(this.toDate).setHours(23, 59, 0, 0),
    //     // status: this.status,
    //   };
    // } else {
    // }
    this.transactions = [];
    this.isLoading = true;
    this.userManagerService.getTransactions(this.params).then(data => {
      if(data.hasOwnProperty('data') && data.data.length > 0)
     {
        this.transactions = data.data;
     }
      this.isLoading = false;
    }).catch(err => {
      this.transactions = [];
      this.isLoading = false;
    }).finally(() => {

    });
  }

  formatYYMMDD(date){
    // console.log(moment.locale())
    // console.log(date);


     var datePipe = new DatePipe(moment.locale());

     let result = datePipe.transform(date, 'yyyy-mm-dd');

    return result;
  }

  triggerCashOut(transaction: any, trigger?: MdePopoverTrigger) {
    this.activeTransaction = transaction.id;
    this.isProcessing = true;
    this.cashoutvalue = undefined;
    $(trigger).dropdown('dispose');
    this.userManagerService.triggerCashout(encodeURIComponent(transaction.refCode)).then(data => {
      if (data.erc == 1) {
        this.cashoutvalue = data.amount;
      } else {
        this.cashoutvalue = undefined;
        this.errorMsg = data.msg;
      }

      this.isProcessing = false;
      $(trigger).dropdown('toggle');
    }).finally(() => {
      this.isProcessing = false;
      this.activeTransaction = null;
    });
  }
  completeCashout(transaction) {
    this.activeTransaction = transaction.id;
    this.isProcessing = true;
    this.userManagerService.completeCashout(transaction.id, this.cashoutvalue).then(data => {
      if (data && ((data.length > 0 && data[0] && data[0]['14'] === true || data[0]['14'] === 'true') || data.erc == 1)) {
        this.modalService.msg = data.msg ? data.msg : data[0].msg;
        this.modalService.title = 'success';
        this.modalService.isSuccess = true;
      } else {
        this.modalService.isSuccess = false;
        this.modalService.msg = data.msg ? data.msg : data[0].msg;
        this.modalService.title = 'error';
      }

      this.modalService.showModal();
    }).finally(() => {
      this.isProcessing = false;
      this.activeTransaction = null;
      this.modalService.destroy();
    });
  }


  updateDate(transDate: any){
    var event = new Date(Number.parseInt(transDate.toString()));
    var options:any = {weekday:'short',year:'numeric',month:'short',day:'numeric'};

    return event.toLocaleDateString(this.translate.currentLang, options);
  }
}
