import { TranslateService } from '@ngx-translate/core';
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams, } from "@angular/common/http";
import * as CryptoJS from "crypto-js";
import { ConfigService } from "../providers/config/config.service";
import { HttpErrResp } from '../interfaces/validation';
import { environment } from './../../environments/environment';

@Injectable()
export class HttpService {
  headers: HttpHeaders | { [header: string]: string | string[] };
  options: any;

  constructor(private http: HttpClient, private configService: ConfigService, private translate: TranslateService) {
    // this.setHeaders('withToken');
  }

  get(api: any, params: any): Promise<any> {
    params.channel_id = 3;
    // this.setHeaders('withToken');

    try {
      const url =
        this.configService.PROXIES.turf_gateway +
        api +
        this.parseParams(params);
      return new Promise((resolve, reject) => {
        this.http.get(url).subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
      });
    } catch (err) {
      return new Promise((reject) => {
        reject(err);
      });
    }
  }
  get_request(gateway: string, api: string, params: any): Promise<any> {
    params.channel_id = params.channel_id ? params.channel_id : this.configService.channel;

    params.lang = params.lang ? params.lang : this.configService.lang
    params = this.parseParams(params);
    const headers = this.getHeaders('', api, params.split('?')[1])
    const options = { headers };
    try {
      const url = gateway + api + params;
      return new Promise((resolve, reject) => {
        this.http.get(url, options)
          .subscribe(data => {
            resolve(data);
          }, error => {
            reject(error);
          });
      });
    } catch (err) {
      return new Promise(reject => {
        reject(err);
      });
    }
  }
  // get_request(url: any, params: any, opts?: any): Promise<any> {
  //   // params.channels = params.channels ? params.channels : 3;
  //   // console.log(url);
  //   try {
  //     url = url + this.parseParams(params);
  //     return new Promise((resolve, reject) => {
  //       this.http.get(url, opts).subscribe(
  //         (data) => {
  //           resolve(data);
  //         },
  //         (error) => {
  //           reject(error);
  //         }
  //       );
  //     });
  //   } catch (err) {
  //     return Promise.reject(err);
  //   }
  // }

  get_file(url: any, params: any, opts?: any): Promise<any> {
    try {
      url = url + this.parseParams(params);
      return new Promise((resolve, reject) => {
        this.http.get(url, opts).subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
      });
    } catch (err) {
      return new Promise((reject) => {
        reject(err);
      }).catch((err: HttpErrorResponse) => {});
    }
  }

  fetch(url: any, params: any): Promise<any> {
    // params.channel_id = params.channel_id ? params.channel_id :3;
    try {
      url = url + this.parseParams(params);
      return new Promise((resolve, reject) => {
        this.http.get(url).subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
      });
    } catch (err) {
      return Promise.reject(err);
    }
  }
  get_no_auth(url: any, params: any): Promise<any> {
    params.channel_id = 3;
    // console.log(url);
    try {
      document.body.style.cursor = "wait";
      url = url + this.parseParams(params);
      return new Promise((resolve, reject) => {
        this.http.get(url).subscribe(
          (data) => {
            document.body.style.cursor = "default";
            resolve(data);
          },
          (error) => {
            document.body.style.cursor = "default";
            reject(error);
          }
        );
      });
    } catch (err) {
      return new Promise((reject) => {
        reject(err);
      });
    }
  }

  _get(api: any, params: any): Promise<any> {
    params.channel_id = 3;
    this.setHeaders("withToken");
    try {
      const url = api + this.parseParams(params);
      // console.log(url);
      return new Promise((resolve, reject) => {
        this.http
          .get(url, {
            headers: {
              Accept: "application/json",
              Authorization: "Bearer 084678ad-2f42-4e5a-954f-dbe675f4b1d5",
            },
          })
          .subscribe(
            (data) => {
              resolve(data);
            },
            (error) => {
              reject(error);
            }
          );
      });
    } catch (err) {
      return new Promise((reject) => {
        reject(err);
      });
    }
  }

  Hget(host: any, api: any, params: any): Promise<any> {
    // params.channel_id = 3;
    // this.setHeaders('withToken');
    const me = this;
    const url = host + api + this.parseParams(params);
    const headers = this.getHeaders('', api, this.parseParams(params).split('?')[1]);
    const options = { headers }

    return new Promise((resolve, reject) => {
      this.http.get(url, options).subscribe(
        (data) => {
          // console.debugerror("data",JSON.stringify(data));
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  authPost(api, url: any, params: any, header?): Promise<any> {
    params.lang = params.lang ? params.lang : this.configService.lang
    params.channel_id = 3;
    params.channel = 3;
    // const me = this;
    const headers = this.getHeaders('auth', url, params)
    url = api + url;
    const options = { headers };
    return new Promise((resolve, reject) => {
      // this.http.post(url, params)
      this.http.post(url, params, options)
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
        });
    });
  }
  authPost2(api, url: any, params: any, header?): Promise<any> {
    params.lang = params.lang ? params.lang : this.configService.lang;
    params.channel_id = 3;
    params.channel = 3;
    // const me = this;
    const headers = this.getHeaders('', url, params)
    url = api + url;
    const options = { headers };
    return new Promise((resolve, reject) => {
      // this.http.post(url, params)
      this.http.post(url, params, options)
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
        });
    });
  }
  parseParams(data: any): any {
    const arr = Object.keys(data);
    let queryParams = arr.length > 0 ? '?' : '';
    for (let i = 0; i < arr.length; i++) {
      if (data[arr[i]] != (undefined || null))
        queryParams = queryParams + arr[i] + '=' + data[arr[i]] + (i < arr.length - 1 ? '&' : '');
    }
    return queryParams;
  }

  parseAuthParams(data: any): any {
    const arr = Object.keys(data);
    let queryParams = "";
    for (let i = 0; i < arr.length; i++) {
      queryParams = queryParams + arr[i] + "=" + data[arr[i]] + "&";
    }
    return encodeURI(queryParams);
  }

  /************************Post Request*******************************/

  PostRequest(host: any, api: any, params: any): Promise<any> {
    const url = host + api;
    // console.log(url);
    return new Promise((resolve, reject) => {
      this.http.post(url, params).subscribe(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  postFileUpload(
    host: any,
    api: any,
    params: any,
    formData?: any
  ): Promise<any> {
    params.channel_id = 3;
    const url = host + api;
    return new Promise((resolve, reject) => {
      this.http
        .post(url, formData) // {headers:headers}
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  // ajax_post

  Post(url: any, params: any, header?, type?): Promise<any> {
    params.channel = this.configService.channel.toString();
    params.channel_id = this.configService.channel.toString();
    const me = this;
    const headers = this.getHeaders(header, url, params);
    const options = { headers };
    return new Promise((resolve, reject) => {
      this.http.post(url, params, options).subscribe(
        (data) => {
          resolve(data);
          // console.log(data);
        },
        (error) => {
          reject(error);
          // console.log(error);
        }
      );
    });
  }

  Post_no_auth(url: any, params: any): Promise<any> {
    //   params.token = CommonUtilities.getSessionStorage('token');
    document.body.style.cursor = "wait";
    return new Promise((resolve, reject) => {
      this.http
        .post(url + this.parseParams(params), params) // {headers:headers}
        .subscribe(
          (data) => {
            resolve(data);
            document.body.style.cursor = "default";
          },
          (error) => {
            reject(error);
            document.body.style.cursor = "default";
          }
        );
    });
  }

  PUT_NO_AUTH(url: any, params: any): Promise<any> {
    this.setHeaders("");
    // params.token =  CommonUtilities.getSessionStorage('token');
    const me = this;
    document.body.style.cursor = "wait";
    return new Promise((resolve, reject) => {
      this.http
        .put(url, params, {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer 084678ad-2f42-4e5a-954f-dbe675f4b1d5",
          },
        })
        .subscribe(
          (data) => {
            document.body.style.cursor = "default";
            resolve(data);
          },
          (error) => {
            document.body.style.cursor = "default";
            // console.debugerror("data",JSON.stringify(error));
            reject(error);
          }
        );
    });
  }

  HttpParamsPost(url: any, data: any) {
    let body = new HttpParams();
    Object.keys(data).forEach(function (key) {
      body = body.append(key, encodeURI(data[key]));
    });
    // console.log('Request body', body);
    return new Promise((resolve, reject) => {
      this.http
        .post(url, body.toString(), {
          headers: new HttpHeaders().set(
            "Content-Type",
            "application/x-www-form-urlencoded"
          ),
        })
        .subscribe(
          (data) => {
            document.body.style.cursor = "default";
            resolve(data);
          },
          (error) => {
            document.body.style.cursor = "default";
            // console.debugerror("data",JSON.stringify(error));
            reject(error);
          }
        );
    });
  }
  generateHash(body) {
    const hash = CryptoJS.HmacSHA256(  body, this.configService.AuthParams.RequestHmacKey );
    return CryptoJS.enc.Base64.stringify(hash);
  }

  HPost(api, url: any, data: any) {
    data.channel = data.channel ? data.channel : 3;
    const body = data;
    const route = api + url;
    data.lang = data.lang ? data.lang : this.configService.lang;
    data.channel_id = this.configService.channel;
    data.channel = this.configService.channel;

    const headers = this.getHeaders("login", url, data);
    const options = { headers };
    return new Promise((resolve, reject) => {
      this.http.post(route, body, options).subscribe(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }, ()=> {
          document.body.style.cursor = "default";
        }
      );
    });
  }
  get_auth(api: any, params: any): Promise<any> {

    params.lang = params.lang || this.configService.lang
    params = this.parseParams(params);
    const headers = this.getHeaders('auth', api, params.split('?')[1]);
    const options = { headers };

    try {
      const url = this.configService.PROXIES.userService + api + params;
      return new Promise((resolve, reject) => {
        this.http.get(url, options)
          .subscribe(data => {
            resolve(data);
          }, error => {
            reject(error);
          });
      });
    } catch (err) {
      return new Promise(reject => {
        reject(err);
      });
    }
  }
  HGet(url: any) {
    let headers = new HttpHeaders();
    headers = headers.set("Content-Type", "application/json");

    // const body = 'Flexbet2020';
    // headers = headers.set('Content-Signature', 'pmubet:' + this.generateHash('pmubet@test') );
    headers = headers.set( "Content-Signature", this.configService.AuthParams.username + ":"
      + this.generateHash(this.configService.AuthParams.RequestHmacKey)
    );
    // + ';charset=UTF-8'
    return new Promise((resolve, reject) => {
      this.http.get(url, { headers }).subscribe(
        (data) => {
          document.body.style.cursor = "default";
          resolve(data);
        },
        (error) => {
          document.body.style.cursor = "default";
          // console.log('data', JSON.stringify(error));
          reject(error);
        }
      );
    });
  }

  ajax_post(url, params) {
    const token = "084678ad-2f42-4e5a-954f-dbe675f4b1d5";
    try {
      const xhttp = new XMLHttpRequest();
      xhttp.onreadystatechange = function () {
        if (this.readyState === 4 && this.status === 200) {
          return Promise.resolve(this.responseText);
        }
      };
      xhttp.open("POST", url, true);
      xhttp.send(params);

      // return $.ajax({
      //     url: url,
      //     type: 'POST',
      //     dataType: 'json',
      //     headers: {
      //         'Authorization': 'Bearer ' + token,
      //     },
      //     data: params,
      // });
    } catch (error) {
      console.error("Error", error);
    }
  }

  host_delete(host: any, api: any, param: any): Promise<any> {
    const me = this;
    const url = host + api + "/" + param;
    // console.debugerror("url",url);
    return new Promise((resolve, reject) => {
      this.http.delete(url, me.options).subscribe(
        (data) => {
          // console.debugerror("data",JSON.stringify(data));
          resolve(data);
        },
        (error) => {
          // console.debugerror("data",JSON.stringify(error));
          resolve(error);
        }
      );
    });
  }

  setHeaders(type): void {
    this.headers = new HttpHeaders();
    // let token = this.shared.token ? this.shared.token: "084678ad-2f42-4e5a-954f-dbe675f4b1d5";
    const token = "084678ad-2f42-4e5a-954f-dbe675f4b1d5";
    this.headers.append("Content-Type", "application/json");
    this.headers.append("Accept", "application/json");
    if (type === "withToken") {
      this.headers.append("Authorization", "Bearer " + token);
    }
    this.options = {
      headers: this.headers,
    };
  }

  getHeaders(type, url?, params?) {
    if (!(typeof params === "string" || params instanceof String)) {
      params = JSON.stringify(params);
    }
    if(url.startsWith('/'))
    url = url.substring(1, url.length);
    // console.log(url)
    // console.log(this.configs.AuthParams.username + '/v2/' + url + params);
    if(!this.configService.useGateway) return new HttpHeaders({})
    switch (type) {
      case "login":
        return new HttpHeaders({
          "Content-Type": "application/json; charset=UTF-8",

          "Content-Signature":
            this.configService.AuthParams.username +
            ":" +
            this.generateHash(
              this.configService.AuthParams.username + "/v2/" + url + params
            ),

          // 'Authorization': this.authorization
        });
      case "auth":
        return new HttpHeaders({
          "Content-Type": "application/json; charset=UTF-8",

          "Content-Signature":
            this.configService.AuthParams.username +
            ":" +
            this.generateHash(
              this.configService.AuthParams.username + "/v2/" + url + params
            ),

          // 'auth': 'true'
          Authorization: this.getToken(),
        });
      case "refreshToken":
        // console.log(this.configs.AuthParams.username + '/'+ url + params)
        return new HttpHeaders({
          "Content-Type": "application/json; charset=UTF-8",

          "Content-Signature":
            this.configService.AuthParams.username +
            ":" +
            this.generateHash(
              this.configService.AuthParams.username + "/" + url + params
            ),
        });

      default:
        return new HttpHeaders({
          "Content-Type": "application/json; charset=UTF-8",
          "Content-Signature":
            this.configService.AuthParams.username +
            ":" +
            this.generateHash(
              this.configService.AuthParams.username + "/v2/" + url + params
            ),
          // 'Authorization': this.authorization
        });
    }
  }

  getToken() {
    return this.configService.USER_AUTHORISATION.access_token;
  }

  processStatusCode(erresp: HttpErrResp){
    var display: string = ""
    switch (erresp.status) {
      case 404: display = "PATH_NOT_FOUND";
        break;
      case 401: display = "UNAUTHORIZED_SIGNATURE";
        break;
      case 500: display = "SERVER_TIMEOUT";
        break;
      case 200: display = "REQUEST_OK";
        break;
      case 400: display = "BAD_REQUEST";
        break;

      default:
        break;
    }
    if(!environment.production)
      console.warn(display + " @ " + JSON.stringify(erresp.error.path).concat("\n"), erresp.message);

  }
}
