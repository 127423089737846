import { TranslateService } from '@ngx-translate/core';
import { HttpService } from "./../../../wrappers/Http";
import { Injectable, ɵConsole } from "@angular/core";
import { StorageService } from "../../storage.service";
import { BehaviorSubject } from "rxjs";
import { ModalService } from "../../modal-service/ModalService";
import { ConfigService } from "../../config/config.service";
import { resolve } from "url";
import { BET } from "src/app/errorMessages";
import { SkeletonServiceService } from "src/app/components/skeleton-service.service";
import { AuthService } from "../../auth/auth.service";

@Injectable({
  providedIn: "root",
})
export class UserManagerService {
  userManagementService: any;
  idleInterval: NodeJS.Timer;
  userIdleTime: any;
  checkIdletime = false;
  expireTime = 5;
  sliderItems: any[] = [];
  siteTitle = "";
  country = "";
  validProviders = [];
  passModal = false;
  resetPass = new BehaviorSubject(0);

  verifyUser = false;
  userID = "";
  userphone = "";
  validationPatterns: any = {
    cni: /^(?=.*[0-9])([a-zA-Z0-9]+)$/,
    email: /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,}$/,
    pin: /^[0-9]*$/,
    phone: /^[0-9]*$/,
    pseudo: /^[a-zA-Z0-9]*$/,
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)\S{6,20}$/
  }

  // confirmpaymentAccount(requestData: {
  //   sid: any;
  //   otp: any;
  //   id: any;
  // }): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     this.requestHandler
  //       .Post(this.configService.PROXIES.gateway + EndPoints.confirmOtpPayment, requestData)
  //       .then((data) => {
  //         if (data.erc && data.erc == '1') {
  //           resolve(data);
  //         } else reject(data);
  //       })
  //       .catch((err) => {
  //         reject(err);
  //       });
  //   });
  // }
  // saveAccount(request: { sid: any; pvd: any; accnt: any }): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     this.requestHandler
  //       .Post(this.configService.PROXIES.gateway + this.configService.EndPoints.savePaymentAccnt, request)
  //       .then((data) => {
  //         if (data.erc && data.erc == '1') {
  //           resolve(data);
  //         } else reject(data);
  //       })
  //       .catch((err) => {
  //         reject(err);
  //       });
  //   });
  // }
  lastActiveTime: any;
  onLogIn = new BehaviorSubject(0);
  securityQuestions: Array<any>;
  defaultLanguages: Array<any> =
    this.skeleton.theme.name == "pmug"
      ? [
          { name: "Français", code: "fr", active: true, image: "assets/images/fr_2.png", value: "fr", },
        ]
      : [
          { name: "Français", code: "fr", active: true, image: "assets/images/fr_2.png", value: "fr", },
          { name: "English", code: "en", active: true, image: "assets/images/en_2.png", value: "en", },
        ];
  defaultSexes: Array<any> = [
    { name: "male", value: "M", },
    { name: "female", value: "F", },
  ];
  isProcessing = false;
  logingIn = false;
  credsValid = true;
  rememberMe = false;
  ticketDetails: any = [];
  private _userData: any;
  // private _username: any;
  private _password: any;
  private _error_msg: string;
  private _countries = [];
  public dialingCodes: Array<any> = [];
  // paymentProviders: Array<any> = new Array(0);
  private _showChangePasswordAlert: boolean;
  public get showChangePasswordAlert(): boolean {
    return this._showChangePasswordAlert;
  }
  public set showChangePasswordAlert(value: boolean) {
    this._showChangePasswordAlert = value;
  }
  public get error_msg(): any {
    return this._error_msg;
  }
  public set error_msg(value: any) {
    this._error_msg = value;
  }
  public get countries(): any {
    return this._countries;
  }
  public set countries(value: any) {
    this._countries = value;
  }
/*   public get username(): any {
    return this._username;
  }
  public set username(value: any) {
    this._username = value;
  } */

  public get password(): any {
    return this._password;
  }
  public set password(value: any) {
    this._password = value;
  }

  public get userData(): any {
    return this._userData;
  }
  public set userData(value: any) {
    this._userData = value;
  }
  constructor(
    public requestHandler: HttpService,
    public storageService: StorageService,
    private configService: ConfigService,
    private authService: AuthService,
    private modalService: ModalService,
    private skeleton: SkeletonServiceService,
    private translate: TranslateService
  ) {}
  public mapUserData(data) {
    if (this.configService.useGateway) {
      return;
    }
    this.userData = {
      login: data.str_Login,
      email: data.str_Email,
      balances: {
        bon: [],
        total: data.dec_CUR_Balance,
        bonus: "0",
        winnings: data.dec_WIN_Balance,
        deposits: data.dec_CUR_Balance,
      },
      birthday: data.dt_AGENT_BIRTHDAY,
      first_name: data.str_Surname,
      last_name: data.str_Name,
      customer_ID: data.lg_AGENT_ID,
      lang: data.str_Lang,
      notif_Channels: "",
      phone_number: data.str_Phone,
      sex: data.str_Sexe,
      status: data.str_Statut,
      town: data.str_Town,
      account_code: data.str_Account_Code,
      pin: data.str_PIN,
      country: data.str_Lieu_Naissance,
      nationality: data.str_Nationalite,
      address: data.str_Address,
      password: data.str_Password,
      birthplace: data.str_Lieu_Naissance,
    };
  }
  // private lang: any = navigator.language;
  private lang: any = this.translate.currentLang;
  confirmpaymentAccount(requestData: { sid: any; otp: any; id: any; }): Promise<any> {
    return new Promise((resolve, reject) => {
      this.requestHandler
        .authPost( this.configService.PROXIES.userService, this.configService.EndPoints.confirmOtpPayment, requestData )
        .then((data) => {
          // if (data.erc && data.erc == "1") {
            resolve(data);
          // } else {
          //   reject(data);
          // }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  verifyTicket(params): Promise<any> {
    return this.requestHandler.get_no_auth(
      this.configService.PROXIES.turf_gateway +
      this.configService.EndPoints.verifyTicket,
      params
    );
  }
  saveAccount(request: { sid: any; pvd: any; accnt: any }): Promise<any> {
    return this.requestHandler.authPost(
          this.configService.PROXIES.userService,
            this.configService.EndPoints.savePaymentAccnt,
          request
        );
  }
  startIdleWatch() {
    // const expireTime = 5;
    this.idleInterval = setInterval(() => {
      this.userIdleTime = this.userIdleTime + 1;
    }, 60000);
    document.addEventListener("click", () => {
      this.userIdleTime = 0;
    });
    document.addEventListener("mousemove", () => {
      this.userIdleTime = 0;
    });
    document.addEventListener("keypress", () => {
      this.userIdleTime = 0;
    });
    document.addEventListener("change", () => {
      this.userIdleTime = 0;
    });
    document.addEventListener("scroll", () => {
      this.userIdleTime = 0;
    });
  }

  register(userData): Promise<any> {
    this.isProcessing = true;
    return this.configService.useGateway
      ? this.requestHandler.HPost(
          this.configService.PROXIES.userService ,
            this.configService.EndPoints["lonaci_register"],
          userData
        )
      : this.requestHandler.get_request(
          this.configService.PROXIES.userService ,
            this.configService.EndPoints["lonagui_register"],
          userData
        );
  }
  getGratuits(param?): Promise<any> {
    return this.requestHandler.get_request(
      this.configService.PROXIES.sportcash_1 ,
        this.configService.EndPoints.gratuits,
      param
    );
  }
  downloadfile(url): Promise<any> {
    return this.requestHandler.get_request("", url, {});
  }

  async login(loginData: any, usePseudo?: boolean): Promise<boolean> {
    let params: any = {
      lang: this.lang,
      channel: 3,
    };
    if (this.configService.useGateway) {
      params.login = loginData.username;
      params.pwd = loginData.password;
    } else {
      params.str_Login = loginData.username;
      params.str_Password = loginData.password;
    }
    if(!usePseudo) {
      var pattern: RegExp;
      pattern = new RegExp(this.validationPatterns.phone);
      if(!pattern.test(params.login.toString().split('+')[1] ? params.login.toString().split('+')[1] : params.login) ) {
       return (this.error_msg = "phone_required", null) ;
      }
    }
    this.logingIn = true;
    this.error_msg = "";
    if (this.configService.useGateway) {
      // console.log('hey')
      return new Promise((resolve, reject) => {
        this.requestHandler
          .HPost( this.configService.PROXIES.userService,
            this.configService.EndPoints[!usePseudo ? "login_with_phonenumber" : "lonaci_login" ],
            params
          )
          .then((response: any) => {
            if (response.hasOwnProperty("erc") && response.erc === "0") {
              if(!response.data){
                this.error_msg = "MESSAGE_CODE_INCORRECT_PASSWORD_LOGIN";
                return;
              }
              this.userData = response.data;
              // if (this.rememberMe) {
              // this.storageService.setCookie(
              //   "session",
              //   this.userData.session,
              //   this.configService.expiry
              // );
              // this.storageService.save("user", this.userData);
              this.saveToken();
              // }
              this.onLogIn.next(1);
              this.startIdleWatch();
              resolve(true);
            } else {
              if(response.erc === "007"){
                this.modalService.openDialog("login_error", this.translate.instant("err_auth_failed",
                      { site_name: this.skeleton.theme.name, contact: this.skeleton.theme.contact }), false)
                      return resolve(false);

              }
              if (response.sessionToken) {
                this.modalService
                  .openDialog(
                    "security_alert",
                    "ml_more_than_one_session",
                    true,
                    response.device
                  )
                  .then(async (data) => {
                    if (data.yesClicked) {
                      this.userData = {};
                      response.customer_ID
                        ? (this.userData.customer_ID = response.customer_ID)
                        : (this.userData.lg_AGENT_ID = response.lg_AGENT_ID);

                      await this.renewSession(params).then(() => {
                        resolve(this.userData.hasOwnProperty("sessionToken"));
                      });
                    } else {
                      this.cleanLocalSession();
                    }
                  });
              }

              if (response.data && response.data.suspended) {
                this.modalService
                  .openDialog("security_alert", "suspend_account_msg", false)
                  .then((data) => {
                    resolve(false);
                  });
                // resolve(false);
                return;
              }
              if(response.msg || response.error.msg) {
                switch (response.msg) {
                  case 'ml_more_than_one_session':
                    this.modalService.openDialog( "security_alert", "ml_more_than_one_session", true, response.data.device )
                    .then(async (data) => {
                      if (data.yesClicked) {
                        this.userData = {};
                        response.data.customer_ID ? (this.userData.customer_ID = response.data.customer_ID)
                          : (this.userData.lg_AGENT_ID = response.data.lg_AGENT_ID);
                        // this.userData.customer_ID = response.data.customer_ID;
                        await this.renewSession(params).then(() => {
                          resolve(this.userData.hasOwnProperty("session"));
                        });
                      } else {
                        this.cleanLocalSession();
                      }
                    });
                    break;

                  case 'ml_erc_client_blocked':
                    this.modalService.openDialog("login_error", this.translate.instant("ml_erc_client_blocked",
                      { site_name: this.skeleton.theme.name, contact: this.skeleton.theme.contact }), false)
                    .then((data) => {
                      resolve(false);
                    });
                    break;

                  default:
                    this.error_msg = response.msg || response.error.msg;
                    this.userData = undefined;
                    resolve(false);
                    break;
                }
                return;
              }
              if (response.erc == '239'){
                this.resetPasswordPrompt();
              }
            }

          })
          .catch((data) => {
            this.userData = undefined;
            if (data.hasOwnProperty("ok") && !data.ok) {
              // console.log(data);
              this.error_msg = data.error.message;
            } else {
              // console.log(data);
              this.error_msg = data.msg ? data.msg : "ml_message_server_error";
              reject(false);
            }
          }).finally(()=>{
            this.logingIn = false;
          });
      });
    } else {
      return new Promise((resolve, reject) => {
        this.requestHandler
          .get_request(
            this.configService.PROXIES.userService, this.configService.EndPoints[ !usePseudo ? "login_with_phonenumber" : "lonagui_login" ],
            params
          )
          .then((response: any) => {

            if ( response && response[0] && response[0].hasOwnProperty("result") && response[0].result == "1" ) {
              this.mapUserData(response[0]);
              this.storageService.save("user", this.userData);
              this.onLogIn.next(1);
              this.startIdleWatch();
              resolve(true);
            } else {
              this.error_msg = response[0] && response[0].str_Statut ? response[0].str_Statut : "ml_message_server_error";
              this.userData = undefined;
              resolve(false);
            }

          })
          .catch((data) => {
            this.userData = undefined;
            if (data.hasOwnProperty("ok") && !data.ok) {
              // console.log(data);
              this.error_msg = "ml_message_server_error";
              console.log(this._error_msg);
            } else {
              this.error_msg = data.msg ? data.msg : "ml_message_server_error";
              reject(false);
            }
          }).finally(()=>{
            this.logingIn = false;
          });
      });
    }
  }

  forgotPassword(){
    this.modalService.destroy();
    this.resetPass.next(1);
    // this.modalService.init(ResetPassowrdComponent, {}, {});
  }
  resetPasswordPrompt(){
    switch (this.skeleton.theme.name) {
      case 'lonaci':
        this.modalService.openDialog("security_alert", "reset_password", true)
        .then((data)=>{
          if (data.yesClicked){
            window.location.href = `${this.configService.PROXIES.homePage}?${this.setLoggedInUserUrlParams()}&page=forgot-password&redirect=${encodeURIComponent(window.location.href)}`
          }
          else{
            this.cleanLocalSession();
          }
        })
        break;
    
      default:
        this.modalService.openDialog("security_alert", "reset_password", true)
        .then((data)=>{
          if (data.yesClicked){
            this.forgotPassword();
          }
          else{
            this.cleanLocalSession();
          }
        })
        break;
    }
  }
  logout(params): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.requestHandler
        .authPost(
          this.configService.PROXIES.userService ,
            this.configService.EndPoints.logout,
          params
        )
        .then((response: any) => {
          this.storageService.setCookie( "session", "", this.configService.expiry );
          this.storageService.deleteItem("user");
          this.userData = undefined;

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  updateUser(userData?): Promise<any> {
    // this.isProcessing = true;

    const params = this.buildObjct(userData ? userData : this.userData);
    let dt = new Date(params.str_Birthdate);

    params.str_Birthdate =
      dt.getDate() + "/" + (dt.getMonth() + 1) + "/" + dt.getFullYear();

    return this.requestHandler.authPost(
      this.configService.PROXIES.userService ,
        this.configService.EndPoints.updateProfile,
      params
    );
  }
  buildObjct(userdata) {
    const params = {
      str_Login: userdata.login.trim(),
      str_Account_Code: userdata.account_code.trim(),
      str_Address: userdata.address,
      str_balances: userdata.balances,
      str_Birthdate: userdata.birthday,
      str_CNI: userdata.cni.trim(),
      str_lg_Agent_Id: userdata.customer_ID,
      str_Email: userdata.email.trim(),
      str_FName: userdata.first_name,
      str_Lang: userdata.lang,
      str_LName: userdata.last_name,
      str_Nationalite: userdata.nationality,
      notif_Channels: userdata.notif_Channels,
      str_Phone_Number: userdata.phone_number.trim(),
      str_Sexe: userdata.sex,
      str_Session: userdata.session,
      str_Town: userdata.town,
      str_Statut: userdata.status,
      str_channel: 3,
      sid: userdata.sid? userdata.sid : ''
    };
    return params;
  }
  changePassword(passwords): Promise<any> {
    this.isProcessing = true;
    return this.requestHandler.authPost(
      this.configService.PROXIES.userService ,
        this.configService.EndPoints.changePassword,
      passwords
    );
  }
  changePin(pins): Promise<any> {
    this.isProcessing = true;
    return this.requestHandler.authPost(
      this.configService.PROXIES.userService,
        this.configService.EndPoints.changePin,
      pins
    );
  }
  findErrorMessage(Code) {
    for (const key in BET) {
      if (BET[key] == Code) {
        return key;
      }
    }
    return "defaultFailed";
  }
  loadSlides(params) {
    const perpage = 20;
    // this.searchdata = searchdata;
    // let param = {page:0, perpage: 20, currentPage: 0 };
    var api= "";
    switch (this.skeleton.theme.name) {
      case "lonani":
        api = this.configService.EndPoints.admin_sliders
        break;

      default:
        api = this.configService.EndPoints.cagnottes
        break;
    }
    return this.requestHandler.get_request(
      this.configService.PROXIES.sportcash, api, params
    );
  }
  getTransactions(params: any): Promise<any> {
    return this.requestHandler.get(
      // this.configService.PROXIES.userService ,
        (this.configService.useGateway
          ? this.configService.EndPoints.transactions
          : this.configService.EndPoints.flexbet_transactions),
      params
    );
  }

  loadPromotions(params: any = {}) {
    return this.requestHandler.get_request(
      this.configService.PROXIES.userService ,
        this.configService.EndPoints.promotions,
      params
    );
  }
  getAccountTransactions(params: any): Promise<any> {
    return this.requestHandler.get_auth(
      // this.configService.PROXIES.userService ,
        (!this.configService.useGateway
          ? this.configService.EndPoints.flexbet_transactions
          : this.skeleton.theme.name === 'lonani' ? this.configService.EndPoints.transactions_ne : this.configService.EndPoints.payment_transactions),
      params
    );
    // return;
  }
  verifyEmail(email) {
    const params: any = {};
    let url: string = "";
    if (!this.configService.useGateway) {
      params.str_Email = email;
      url = this.configService.EndPoints.verifyEmail_flexbet;
    } else {
      params.email = email;
      url = this.configService.EndPoints.verifyEmail;
    }
    return this.requestHandler.Hget(
      this.configService.PROXIES.userService,
      url,
      params
    );
  }
  checkProvider(phone_number) {
    var validPhone = false;
    if (!this.validProviders.length) return true;
    for (let prov of this.validProviders) {
      // console.log(prov + "   " + phone_number.toString().substring(0,2))
      if (phone_number.toString().startsWith(prov)) {
        validPhone = true;
        return validPhone;
      }
    }
  }
  validatePassword(word) {
    return /^(?=.{6,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])/.test(word);
  }
  verifyPhone(params: any) {
    params.lang = this.configService.lang
    return this.requestHandler.Hget(
      this.configService.PROXIES.userService,
      this.configService.EndPoints.verifyPhone,
      params
    );
  }
  verifyUserName(username: string) {
    var param: any = {};
    let url: string = "";
    if (!this.configService.useGateway) {
      url = this.configService.EndPoints.verifyUserName_flexbet;
      param.str_Login = username;
    } else {
      param.username = username;
      url = this.configService.EndPoints.verifyUserName;
    }

    return this.requestHandler.Hget(
      this.configService.PROXIES.userService,
      url,
      param
    );
  }
  verifyAccount(params: any): Promise<any> {
    return this.requestHandler.authPost2(
      this.configService.PROXIES.userService ,
        this.configService.EndPoints.recover1,
      params
    );
  }
  resetPassword(params: any): Promise<any> {
    return this.requestHandler.authPost2(
      this.configService.PROXIES.userService,
        this.configService.EndPoints.recover2,
      params

    );
  }
  resentActivation(params) {
    return this.requestHandler.authPost2(
      this.configService.PROXIES.userService ,
        this.configService.EndPoints.resendActivation,
      params
    );
  }
  updateUserAttribute(attrib: string, value: any) {
    this.userData[attrib] = value;
    // console.log(this.userData);
    if (this.storageService.getObject("user", false)) {
      this.storageService.save("user", this.userData);
    }
  }
  confirmAccount(params) {
    return this.requestHandler.authPost2(this.configService.PROXIES.userService ,
      this.configService.EndPoints.confirm_account, params)
  }
  getSecurityQuestions() {
    const params: any = {};
    params.channel = 7;
    params.versioncode = 3;
    params.date_jr = "";
    // console.log("security questions", this.securityQuestions);
    if (this.configService.useGateway) {
      this.requestHandler.Hget(
          this.configService.PROXIES.sportcash, this.configService.EndPoints.securityQuestions, {}
        )
        .then((response) => {
          this.securityQuestions = response.data;
        });
    } else {
        this.requestHandler.Hget(
          this.configService.PROXIES.turf_gateway, this.configService.TurfEndpoints.getActiveGames, params
          )
          .then((response) => {
            this.securityQuestions = response.data.AC[1];
            // this.countries = response.data.AC[2];
            // this.configService.country_code = []
          });
    }
  }
  triggerCashout(refCode: any): Promise<any> {
    return this.requestHandler.Hget(
      this.configService.PROXIES.turf_gateway,
      this.configService.EndPoints.cashout + "/" + refCode + "",
      {}
    );
  }
  completeCashout(id: any, cashoutvalue: number) {
    const params = {
      bid: id,
      uid: this.userData.lg_AGENT_ID,
      amt: cashoutvalue,
    };
    return this.requestHandler.PostRequest(
      this.configService.PROXIES.turf_gateway,
      this.configService.EndPoints.completeCashout +
        this.requestHandler.parseParams(params),
      params
    );
  }
  getCountryList(): Promise<boolean> {
    if(this.countries && this.countries.length > 0) return null;
    if (this.configService.useGateway) {
      return new Promise((resolve, reject) => {
        fetch("assets/countries.json")
          .then((res) => res.json())
          .then((res) => {
            this.countries = res;
            resolve(true);
          })
          .catch((err) => {
            reject(false);
            // console.log('error is ', err);
          });
      });
    } else {
      // if(!this.countries || this.countries.length<0){
      const params: any = {};
      params.channel = 7;
      params.versioncode = 3;
      params.date_jr = "";
      return new Promise((resolve, reject) => {
        this.requestHandler
          .Hget(
            this.configService.PROXIES.turf_gateway,
            this.configService.TurfEndpoints.getActiveGames,
            params
          )
          .then((response) => {
            this.countries = response.data.AC[2];
            // console.log(this.countries, response.data.AC);
            resolve(this.countries);
            // this.configService.country_code = []
          })
          .catch((err) => {
            reject(err);
          });
      });
      // }
    }
  }
  resendActivation(value: any) {
    return this.requestHandler.authPost2(
      this.configService.PROXIES.userService ,
        this.configService.EndPoints.resendMail,
      { email: value,origin: 'PMU_LONANI' }
    );
  }
  async refreshUsersContext(...userID) {
    // console.log(userID);
    if (this.userData || userID[0]) {
      this.requestHandler
        .get("user/" + (userID[0] ? userID[0] : this.userData.session), {})
        .then((data) => {
          if (!data.hasOwnProperty("erc") || data.erc == "0") {
            // console.log('invalid User');
          } else if(data.hasOwnProperty("erc") && data.erc == '239'){
            this.resetPasswordPrompt();
          }
          else {
            if (data.hasOwnProperty("session") && data.session != null) {
              this.userData = data;

              this.storageService.save("user", this.userData.session);
            }
          }
        });
    }
  }
  refreshSession() {
    this.requestHandler
      .Post(this.configService.PROXIES.userService + "/user/session/refresh", {
        agent: this.userData.customer_ID
          ? this.userData.customer_ID
          : this.userData.lg_AGENT_ID,
      })
      .then((data) => {
        if (!data.hasOwnProperty("erc") || data.erc == "0") {
          // console.log('invalid User');
        } else if(data.hasOwnProperty("erc") && data.erc == '239'){
          this.resetPasswordPrompt();
        }
        else {
          this.userData.session = data.data;
          this.storageService.save("user", this.userData.session);
        }
      });
  }
  async renewSession(params:any = {}) {
    params.agent = this.userData.customer_ID
    ? this.userData.customer_ID
    : this.userData.lg_AGENT_ID
    return Promise.resolve(
      this.requestHandler
        .HPost(this.configService.PROXIES.userService , this.configService.EndPoints.renewSession, params)
        .then((data: any) => {
          if (
            (data.hasOwnProperty("erc") && data.erc == "0") ||
            data.erc == 0
          ) {
            this.userData = data.data;
              this.onLogIn.next(1);

            // if (this.rememberMe) {
            //   this.storageService.save('user', this.userData.lg_AGENT_ID);
            // }
            this.saveToken();
            // this.storageService.setCookie(
            //   "session",
            //   this.userData.session,
            //   this.configService.expiry
            // );
            // this.storageService.save("user", this.userData);
          } else if(data.hasOwnProperty("erc") && data.erc == '239'){
            this.resetPasswordPrompt();
          }
          else {
            // console.log('invalid User');
          }
        })
    );
  }
  authSession(id) {
    const session = id.session;

    return Promise.resolve(
      this.requestHandler
        .get_request(
          this.configService.PROXIES.userService,
            this.configService.EndPoints.lonaci_login +
            "/" +
            session,
            {refresh_token: this.configService.USER_AUTHORISATION.refresh_token}
        )
        .then((data: any) => {
          if (
            (data.hasOwnProperty("erc") && data.erc == "0") ||
            data.erc == 0
          ) {
            this.userData = data.data;
            this.onLogIn.next(1);

            this.saveToken();
          } else if(data.hasOwnProperty("erc") && data.erc == '239'){
            this.resetPasswordPrompt();
          }
          else {
            this.userData = undefined;

          }
        })
        .catch((err) => {
        })
    );
  }
  setLoggedInUserUrlParams(){
    return (this.userData &&this.userData.session) ? `session=${this.userData.session}` :''
    // &token=${this.configService.USER_AUTHORISATION.access_token}&refresh-token=${this.configService.USER_AUTHORISATION.refresh_token}&expire_at=${this.configService.USER_AUTHORISATION.expiry}&token_duration=${this.configService.USER_AUTHORISATION.token_duration}

  }
  cleanLocalSession() {
    this.onLogIn.next(0);
    this.resetPass.next(0);
    this.storageService.deleteItem("user");
    this.userData = undefined;
  }
  updateUserBalance() {
    let params = {
      lg_Client_ID: this.userData.customer_ID,
    };
    this.requestHandler
      .get_request(
        this.configService.PROXIES.userService ,
          this.configService.EndPoints.user_balance,
        params
      )
      .then((res) => {
        // console.log(res);
      });
  }

  public downloadGuide(url, name?) {
    let pdfReqBody = {
      // url: url,
      // title: name,
      // any usefull data you would want to send to service
    };

    return this.requestHandler
      .get_file(url, pdfReqBody, { responseType: "blob"})
      .then((res) => {
        let blob = new Blob([res], { type: "application/pdf" });
        let pdfUrl = window.URL.createObjectURL(blob);

        var PDF_link = document.createElement("a");
        PDF_link.href = pdfUrl;
        //   TO OPEN PDF ON BROWSER IN NEW TAB
        // window.open(pdfUrl, '_blank');
        //   TO DOWNLOAD PDF TO YOUR COMPUTER
        PDF_link.download = `${name.replaceAll(" ", "-")}`;
        PDF_link.click();
      });
  }
  saveToken(userData?){
    userData = userData ? userData : this.userData
    this.configService.USER_AUTHORISATION = {access_token:(userData.access_token && userData.access_token.indexOf('Bearer')>-1 ? "" : 'Bearer ') + userData.access_token, expiry: new Date(userData.expiry || userData.expire_at), refresh_token:userData.refresh_token, token_duration:userData.token_duration }
    this.saveNewSession(this.userData.session);

    setTimeout(() => {
      if(this.userData && this.userData.customer_ID && this.configService.USER_AUTHORISATION.refresh_token){
        this.authService.getTokenRequest()
        .then(res=>{
          let userDta:any = this.configService.USER_AUTHORISATION;
          userDta.expire_at = this.configService.USER_AUTHORISATION.expiry;
          this.saveToken(userDta);
        })
      }

    }, userData.token_duration || this.userData.token_duration);
  }
  saveNewSession(sessionID: any) {
    this.storageService.setCookie(
        "session",
        this.userData.session,
        this.configService.expiry
      );
    this.storageService.save('user', sessionID);
    this.storageService.save('auth',  this.configService.USER_AUTHORISATION)

  }

  quickSignup(userData): Promise<any> {
    this.isProcessing = true;
    return this.requestHandler.authPost(
      this.configService.PROXIES.userService,
        this.configService.EndPoints.lonaci_register,
      userData
    );
  }

  resendSMS(params: { phone: any }) {
    return this.requestHandler.authPost(
      this.configService.PROXIES.userService, this.configService.EndPoints.resendVerificationCodeBySMS,
      params
    );
  }

  confirmCodeRequest(data): Promise<any> {
    return this.requestHandler.authPost(
      this.configService.PROXIES.userService ,
        this.configService.EndPoints.confirmCodeURL,
      data
    );
  }
}
